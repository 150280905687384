import { TimelyPlanName } from './TimelyPlanName';
import { PricingCalculationMode } from './PricingCalculationMode';

import { PricingRegion } from './PricingRegion';
import { SmsPricingRegion } from './SmsPricingRegion';

enum CurrencyCode {
	AUSTRALIA = 'AUD',
	GREAT_BRITAIN = 'GBP',
	NEW_ZEALAND = 'NZD',
	UNITED_STATES = 'USD',
}

enum CurrencySign {
	DOLLAR = '$',
	POUND = '£',
}

type PricingMatrixSoloPrices = Record<TimelyPlanName, number> & {
	/** @deprecated The 'Basic' plan, previously called 'Start', is no longer available to new customers */
	[TimelyPlanName.BASIC]: number,
};

type PricingMatrixStaffPrices = Record<TimelyPlanName, number> & {
	/** @deprecated The 'Basic' plan, previously called 'Start', is no longer available to new customers */
	[TimelyPlanName.BASIC]: number,
};

type PricingMatrixFixedPrices = Partial<Record<TimelyPlanName, number>>;

type PricingMatrixCustomPackages = Record<TimelyPlanName, boolean>;

export type PricingMatrixEntry = {
	currencyCode: CurrencyCode,
	sign: CurrencySign,
	prices: {
		solo: PricingMatrixSoloPrices,
		staff: PricingMatrixStaffPrices,
		fixed: PricingMatrixFixedPrices,
		customPackages: PricingMatrixCustomPackages,
		calculationMode: PricingCalculationMode,
	},
	tag: string,
	sms: number,
};

type PricingMatrix = Record<PricingRegion, PricingMatrixEntry>;

export default function getPricingMatrix(): PricingMatrix {
	const pricingMatrix: PricingMatrix = {
		[PricingRegion.IRELAND]: {
			currencyCode: CurrencyCode.GREAT_BRITAIN,
			sign: CurrencySign.POUND,
			prices: {
				solo: {
					[TimelyPlanName.BASIC]: 15,
					[TimelyPlanName.BUILD]: 20,
					[TimelyPlanName.ELEVATE]: 30,
					[TimelyPlanName.INNOVATE]: 35,
				},
				staff: {
					[TimelyPlanName.BASIC]: 15,
					[TimelyPlanName.BUILD]: 20,
					[TimelyPlanName.ELEVATE]: 25,
					[TimelyPlanName.INNOVATE]: 30,
				},
				fixed: {
					[TimelyPlanName.ELEVATE]: 200,
					[TimelyPlanName.INNOVATE]: 250,
				},
				customPackages: {
					[TimelyPlanName.BASIC]: false,
					[TimelyPlanName.BUILD]: false,
					[TimelyPlanName.ELEVATE]: true,
					[TimelyPlanName.INNOVATE]: true,
				},
				calculationMode: PricingCalculationMode.STAFF_MULTIPLE,
			},
			tag: '',
			sms: 8,
		},
		[PricingRegion.GREAT_BRITAIN]: {
			currencyCode: CurrencyCode.GREAT_BRITAIN,
			sign: CurrencySign.POUND,
			prices: {
				solo: {
					[TimelyPlanName.BASIC]: 15,
					[TimelyPlanName.BUILD]: 21,
					[TimelyPlanName.ELEVATE]: 32,
					[TimelyPlanName.INNOVATE]: 37,
				},
				staff: {
					[TimelyPlanName.BASIC]: 15,
					[TimelyPlanName.BUILD]: 21,
					[TimelyPlanName.ELEVATE]: 26,
					[TimelyPlanName.INNOVATE]: 32,
				},
				fixed: {
					[TimelyPlanName.ELEVATE]: 208,
					[TimelyPlanName.INNOVATE]: 260,
				},
				customPackages: {
					[TimelyPlanName.BASIC]: false,
					[TimelyPlanName.BUILD]: false,
					[TimelyPlanName.ELEVATE]: true,
					[TimelyPlanName.INNOVATE]: true,
				},
				calculationMode: PricingCalculationMode.STAFF_MULTIPLE,
			},
			tag: '',
			sms: 6,
		},
		[PricingRegion.CANADA]: {
			currencyCode: CurrencyCode.UNITED_STATES,
			sign: CurrencySign.DOLLAR,
			prices: {
				solo: {
					[TimelyPlanName.BASIC]: 15,
					[TimelyPlanName.BUILD]: 25,
					[TimelyPlanName.ELEVATE]: 35,
					[TimelyPlanName.INNOVATE]: 40,
				},
				staff: {
					[TimelyPlanName.BASIC]: 15,
					[TimelyPlanName.BUILD]: 20,
					[TimelyPlanName.ELEVATE]: 25,
					[TimelyPlanName.INNOVATE]: 30,
				},
				fixed: {
					[TimelyPlanName.ELEVATE]: 225,
					[TimelyPlanName.INNOVATE]: 275,
				},
				customPackages: {
					[TimelyPlanName.BASIC]: false,
					[TimelyPlanName.BUILD]: false,
					[TimelyPlanName.ELEVATE]: true,
					[TimelyPlanName.INNOVATE]: true,
				},
				calculationMode: PricingCalculationMode.SOLO_PLUS_STAFF_MULTIPLE,
			},
			tag: '',
			sms: 5,
		},
		[PricingRegion.REST_OF_THE_WORLD]: {
			currencyCode: CurrencyCode.UNITED_STATES,
			sign: CurrencySign.DOLLAR,
			prices: {
				solo: {
					[TimelyPlanName.BASIC]: 15,
					[TimelyPlanName.BUILD]: 25,
					[TimelyPlanName.ELEVATE]: 35,
					[TimelyPlanName.INNOVATE]: 40,
				},
				staff: {
					[TimelyPlanName.BASIC]: 15,
					[TimelyPlanName.BUILD]: 20,
					[TimelyPlanName.ELEVATE]: 25,
					[TimelyPlanName.INNOVATE]: 30,
				},
				fixed: {
					[TimelyPlanName.ELEVATE]: 225,
					[TimelyPlanName.INNOVATE]: 275,
				},
				customPackages: {
					[TimelyPlanName.BASIC]: false,
					[TimelyPlanName.BUILD]: false,
					[TimelyPlanName.ELEVATE]: true,
					[TimelyPlanName.INNOVATE]: true,
				},
				calculationMode: PricingCalculationMode.SOLO_PLUS_STAFF_MULTIPLE,
			},
			tag: '',
			sms: 8,
		},
		[PricingRegion.UNITED_STATES]: {
			currencyCode: CurrencyCode.UNITED_STATES,
			sign: CurrencySign.DOLLAR,
			prices: {
				solo: {
					[TimelyPlanName.BASIC]: 15,
					[TimelyPlanName.BUILD]: 25,
					[TimelyPlanName.ELEVATE]: 35,
					[TimelyPlanName.INNOVATE]: 40,
				},
				staff: {
					[TimelyPlanName.BASIC]: 15,
					[TimelyPlanName.BUILD]: 20,
					[TimelyPlanName.ELEVATE]: 25,
					[TimelyPlanName.INNOVATE]: 30,
				},
				fixed: {
					[TimelyPlanName.ELEVATE]: 225,
					[TimelyPlanName.INNOVATE]: 275,
				},
				customPackages: {
					[TimelyPlanName.BASIC]: false,
					[TimelyPlanName.BUILD]: false,
					[TimelyPlanName.ELEVATE]: true,
					[TimelyPlanName.INNOVATE]: true,
				},
				calculationMode: PricingCalculationMode.SOLO_PLUS_STAFF_MULTIPLE,
			},
			tag: '',
			sms: 5,
		},
		[PricingRegion.NEW_ZEALAND]: {
			currencyCode: CurrencyCode.NEW_ZEALAND,
			sign: CurrencySign.DOLLAR,
			prices: {
				solo: {
					[TimelyPlanName.BASIC]: 25,
					[TimelyPlanName.BUILD]: 39,
					[TimelyPlanName.ELEVATE]: 59,
					[TimelyPlanName.INNOVATE]: 70,
				},
				staff: {
					[TimelyPlanName.BASIC]: 25,
					[TimelyPlanName.BUILD]: 35,
					[TimelyPlanName.ELEVATE]: 45,
					[TimelyPlanName.INNOVATE]: 55,
				},
				fixed: {
					[TimelyPlanName.ELEVATE]: 374,
					[TimelyPlanName.INNOVATE]: 455,
				},
				customPackages: {
					[TimelyPlanName.BASIC]: false,
					[TimelyPlanName.BUILD]: false,
					[TimelyPlanName.ELEVATE]: true,
					[TimelyPlanName.INNOVATE]: true,
				},
				calculationMode: PricingCalculationMode.SOLO_PLUS_STAFF_MULTIPLE,
			},
			tag: 'and exclude GST',
			sms: 10,
		},
		[PricingRegion.AUSTRALIA]: {
			currencyCode: CurrencyCode.AUSTRALIA,
			sign: CurrencySign.DOLLAR,
			prices: {
				solo: {
					[TimelyPlanName.BASIC]: 25,
					[TimelyPlanName.BUILD]: 39,
					[TimelyPlanName.ELEVATE]: 59,
					[TimelyPlanName.INNOVATE]: 70,
				},
				staff: {
					[TimelyPlanName.BASIC]: 25,
					[TimelyPlanName.BUILD]: 35,
					[TimelyPlanName.ELEVATE]: 45,
					[TimelyPlanName.INNOVATE]: 55,
				},
				fixed: {
					[TimelyPlanName.ELEVATE]: 374,
					[TimelyPlanName.INNOVATE]: 455,
				},
				customPackages: {
					[TimelyPlanName.BASIC]: false,
					[TimelyPlanName.BUILD]: false,
					[TimelyPlanName.ELEVATE]: true,
					[TimelyPlanName.INNOVATE]: true,
				},
				calculationMode: PricingCalculationMode.SOLO_PLUS_STAFF_MULTIPLE,
			},
			tag: 'and exclude GST',
			sms: 8,
		},
	};

	return pricingMatrix;
}

type SmsPricingThresholdPrices = {
	price: string,
	single: string,
};

type SmsPricingEntry = {
	currency: CurrencyCode,
	freeSmsPerStaffPerMonth: Record<Exclude<TimelyPlanName, TimelyPlanName.BASIC>, number>,
	100: SmsPricingThresholdPrices,
	500: SmsPricingThresholdPrices,
	1000: SmsPricingThresholdPrices,
	5000: SmsPricingThresholdPrices,
	10000: SmsPricingThresholdPrices,
};

type SmsPricing = Record<SmsPricingRegion, SmsPricingEntry>;

export function getSmsPricing(): SmsPricing {
	const smsPricing: SmsPricing = {
		[SmsPricingRegion.NEW_ZEALAND]: {
			currency: CurrencyCode.NEW_ZEALAND,
			freeSmsPerStaffPerMonth: {
				[TimelyPlanName.BUILD]: 100,
				[TimelyPlanName.ELEVATE]: 150,
				[TimelyPlanName.INNOVATE]: 200,
			},
			100: {
				price: '$15',
				single: '15c',
			},
			500: {
				price: '$65',
				single: '13c',
			},
			1000: {
				price: '$120',
				single: '12c',
			},
			5000: {
				price: '$500',
				single: '10c',
			},
			10000: {
				price: '$950',
				single: '9.5c',
			},
		},
		[SmsPricingRegion.AUSTRALIA]: {
			currency: CurrencyCode.AUSTRALIA,
			freeSmsPerStaffPerMonth: {
				[TimelyPlanName.BUILD]: 100,
				[TimelyPlanName.ELEVATE]: 200,
				[TimelyPlanName.INNOVATE]: 300,
			},
			100: {
				price: '$15',
				single: '15c',
			},
			500: {
				price: '$65',
				single: '13c',
			},
			1000: {
				price: '$120',
				single: '12c',
			},
			5000: {
				price: '$500',
				single: '10c',
			},
			10000: {
				price: '$900',
				single: '9c',
			},
		},
		[SmsPricingRegion.UNITED_STATES]: {
			currency: CurrencyCode.UNITED_STATES,
			freeSmsPerStaffPerMonth: {
				[TimelyPlanName.BUILD]: 100,
				[TimelyPlanName.ELEVATE]: 200,
				[TimelyPlanName.INNOVATE]: 300,
			},
			100: {
				price: '$10',
				single: '10c',
			},
			500: {
				price: '$25',
				single: '5c',
			},
			1000: {
				price: '$45',
				single: '4.5c',
			},
			5000: {
				price: '$200',
				single: '4c',
			},
			10000: {
				price: '$350',
				single: '3.5c',
			},
		},
		[SmsPricingRegion.GREAT_BRITAIN]: {
			currency: CurrencyCode.GREAT_BRITAIN,
			freeSmsPerStaffPerMonth: {
				[TimelyPlanName.BUILD]: 100,
				[TimelyPlanName.ELEVATE]: 200,
				[TimelyPlanName.INNOVATE]: 300,
			},
			100: {
				price: '£10',
				single: '10p',
			},
			500: {
				price: '£45',
				single: '9p',
			},
			1000: {
				price: '£80',
				single: '8p',
			},
			5000: {
				price: '£350',
				single: '7p',
			},
			10000: {
				price: '£600',
				single: '6p',
			},
		},
		[SmsPricingRegion.CANADA]: {
			currency: CurrencyCode.UNITED_STATES,
			freeSmsPerStaffPerMonth: {
				[TimelyPlanName.BUILD]: 100,
				[TimelyPlanName.ELEVATE]: 200,
				[TimelyPlanName.INNOVATE]: 300,
			},
			100: {
				price: '$15',
				single: '15c',
			},
			500: {
				price: '$50',
				single: '10c',
			},
			1000: {
				price: '$95',
				single: '9.5c',
			},
			5000: {
				price: '$400',
				single: '8c',
			},
			10000: {
				price: '$750',
				single: '7.5c',
			},
		},
		[SmsPricingRegion.INDIA]: {
			currency: CurrencyCode.UNITED_STATES,
			freeSmsPerStaffPerMonth: {
				[TimelyPlanName.BUILD]: 100,
				[TimelyPlanName.ELEVATE]: 200,
				[TimelyPlanName.INNOVATE]: 300,
			},
			100: {
				price: '$6',
				single: '6c',
			},
			500: {
				price: '$25',
				single: '5c',
			},
			1000: {
				price: '$45',
				single: '4.5c',
			},
			5000: {
				price: '$200',
				single: '4c',
			},
			10000: {
				price: '$300',
				single: '3c',
			},
		},
		[SmsPricingRegion.IRELAND]: {
			currency: CurrencyCode.GREAT_BRITAIN,
			freeSmsPerStaffPerMonth: {
				[TimelyPlanName.BUILD]: 100,
				[TimelyPlanName.ELEVATE]: 200,
				[TimelyPlanName.INNOVATE]: 300,
			},
			100: {
				price: '£10',
				single: '10p',
			},
			500: {
				price: '£40',
				single: '8p',
			},
			1000: {
				price: '£75',
				single: '7.5p',
			},
			5000: {
				price: '£300',
				single: '6p',
			},
			10000: {
				price: '£550',
				single: '5.5p',
			},
		},
		[SmsPricingRegion.NETHERLANDS]: {
			currency: CurrencyCode.UNITED_STATES,
			freeSmsPerStaffPerMonth: {
				[TimelyPlanName.BUILD]: 100,
				[TimelyPlanName.ELEVATE]: 200,
				[TimelyPlanName.INNOVATE]: 300,
			},
			100: {
				price: '$20',
				single: '20c',
			},
			500: {
				price: '$90',
				single: '18c',
			},
			1000: {
				price: '$170',
				single: '17c',
			},
			5000: {
				price: '$725',
				single: '14.5c',
			},
			10000: {
				price: '$1350',
				single: '13.5c',
			},
		},
		[SmsPricingRegion.NORWAY]: {
			currency: CurrencyCode.UNITED_STATES,
			freeSmsPerStaffPerMonth: {
				[TimelyPlanName.BUILD]: 100,
				[TimelyPlanName.ELEVATE]: 200,
				[TimelyPlanName.INNOVATE]: 300,
			},
			100: {
				price: '$15',
				single: '15c',
			},
			500: {
				price: '$70',
				single: '14c',
			},
			1000: {
				price: '$130',
				single: '13c',
			},
			5000: {
				price: '$600',
				single: '12c',
			},
			10000: {
				price: '$1100',
				single: '11c',
			},
		},
		[SmsPricingRegion.REST_OF_THE_WORLD]: {
			currency: CurrencyCode.UNITED_STATES,
			freeSmsPerStaffPerMonth: {
				[TimelyPlanName.BUILD]: 100,
				[TimelyPlanName.ELEVATE]: 200,
				[TimelyPlanName.INNOVATE]: 300,
			},
			100: {
				price: '$10',
				single: '10c',
			},
			500: {
				price: '$25',
				single: '5c',
			},
			1000: {
				price: '$45',
				single: '4.5c',
			},
			5000: {
				price: '$200',
				single: '4c',
			},
			10000: {
				price: '$350',
				single: '3.5c',
			},
		},
	};

	return smsPricing;
}
