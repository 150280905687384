import React from 'react';
import Header from "./header";
// import PromoBanner from './components/promo-banner';
import DropDownNav from "./components/nav/drop-down-nav";
import Footer from "./components/footer";
import CookieConsent from "./components/cookie-consent";
import BookACallModal from "./components/book-a-call-modal";
import ReactHintFactory from 'react-hint';
import PreviewBar from './preview-bar';

/**
 * This banner currently causes Cumulative Layout Shift issues due to the geolocation taking extra time to find 
 * A users location before loading. which caused the content below to shift and cause GSC errors.
 */

/**
 * If a banner should show on every page, its data can be set here.
 * See also `base.twig` to add the promo banner for WP pages.
 */
// const promoBannerMock = (() => {
// 	// return null;

// 	return {
// 		block: {
// 			content: {
// 				message: 'Want a better way to take in-salon payments? Say hello to NEW TimelyPay terminals.',
// 				button_text: 'Learn more',
// 				link: '/timelypay/',
// 				region: 'tp-terminals',
// 				bg_colour: 'midnight',
// 			},
// 		},
// 		displayName: 'site-wide-promo-banner',
// 	};
// })();

const ReactHint = ReactHintFactory(React);

const BookACallModalMock = {
	type: "book-a-call-modal",
	content: {
		items: [
			{
				quote:
					"\u201cThe Timely team were able to provide answers to all of my questions and solutions to my problems\u201d",
				customer: "Leah Miller",
				business: "Waxed, Melbourne",
			},
			{
				quote:
					"\u201cThe best thing is that you guys aren\u2019t too business like. I feel like I\u2019m working with human beings and that I can trust you\u201d",
				customer: "Megumi Kida",
				business: "Kida, New York City",
			},
			{
				quote:
					"\u201cTimely has such a great customer team who really listen to us and understand our business and challenges\u201d",
				customer: "Light ",
				business: "Educational Light",
			},
		],
		bgImg:
			"https://www.gettimely.com/wp-content/uploads/2020/04/book-a-call-bg.jpg",
		successMessage:
			'<p>&nbsp;</p>\n<h2>We&#8217;ll be in touch!</h2>\n<p>We&#8217;ve found it useful to talk through your experience using<br />\nTimely. Why not start a free trial and have a look around<br />\nbefore we chat? It&#8217;s obligation free!</p>\n<p><a class="Button Button--red" href="https://app.gettimely.com/register">Start a free trial</a></p>\n',
		message:
			'<p>&nbsp;</p>\n<h2>Book a call</h2>\n<p class="u-mb4">We&#8217;ll chat about your business, answer any questions you have, and show you how Timely can help. <span class="au-only">Alternatively, call us on <a class="nowrap" href="tel:+61 3 8518 4957">+61 3 8518 4957</a> </span><span class="nz-only">Alternatively, call us on <a class="nowrap" href="tel:+64 4 488 7012">+64 4 488 7012</a> </span><span class="gb-only">Alternatively, call us on <a class="nowrap" href="tel:+44 20 3808 0465">+44 20 3808 0465</a></span></p>\n',
	},
};

export default function Layout(props) {
	const { children, post, notificationBar, footer, preview, classNames } = props;
	return (
		<div className={`page-wrapper ${classNames}`}>
			<Header {...props} />
			<div id="NavWrapper">
				<DropDownNav block={props.nav} notificationBar={notificationBar} />
			</div>
			{preview && (<PreviewBar post={post} />)}

			{/* {promoBannerMock && <PromoBanner {...promoBannerMock}/>} */}

			{children}

			<Footer block={{ content: footer }} />
			<BookACallModal block={BookACallModalMock} />
			<CookieConsent />
			<ReactHint
				events={{ click: false, hover: true }}
				onRenderContent={(target, content) => {
					return (
						<div
							className='react-hint__content'
							dangerouslySetInnerHTML={{ __html: content }}
						/>
					);
				}}
			/>

		</div >
	);
}
